import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import InPageImage from '../in-page-image'

import './style/discount-banner.scss'
import { plans } from '../../../../../plans.js'

const DiscountBanner = () => (
  (plans.all.adDiscount !== 0)
    ? <section className='discount-banner'>
      <a
        href='/pricing'
        dataElementLocation={DataElementLocations.HEADER}
        dataElementLabel={'discount-pricing'}
        dataElementId={'discount-banner-pricing'}>
        <div className='container'>
          <div className='row'>
            <div className='col-6 col-md-3 col-lg-3 col-xl-2 pb-3'>
              <InPageImage fileName='discount60.png' className='discount-badge' alt={'Sale ' + plans.all.adDiscount + '% Off'} />
            </div>
            <div className='col-6 col-md-9 col-lg-9 col-xl-10 py-3 py-xl-4 discount-content'>
              <div className='discount-text'>
                <h3>Get {plans.all.adDiscount}%* off and start building your site today!</h3>
                <p className='mb-0'>See promotional details below.</p>
              </div>
              <Button
                href='#'
                color='cta'
                variant='contained'
                size='medium'
                className='discount-button'>
                See Pricing
              </Button>
            </div>
          </div>
        </div>
      </a>
    </section>
    : ''
)

export default DiscountBanner
