import React from 'react'
import PropTypes from 'prop-types'

import Button from '@eig-builder/core-ui/Button'
import { goTo } from './../../helpers/go-to-helper'

import './style/cta-blue.scss'

const CtaBlue = ({ title, buttonTitle, buttonLink, currentPage, dataElementLocation, eventName }) => (
  <div className='blue-cta'>{ title }
    <Button
      onClick={() => {
        goTo(buttonLink)
      }}
      dataElementLocation={dataElementLocation}
      dataElementLabel={'signup'}
      dataElementId={currentPage + '-item-signup'}
      color='primary'
      variant='contained'
      className='signup-button button-xl button-blue'
      size='large'>
      { buttonTitle }
    </Button>
  </div>
)

CtaBlue.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  eventName: PropTypes.string,
  buttonLink: PropTypes.string.isRequired,
  dataElementLocation: PropTypes.string,
  currentPage: PropTypes.string
}

export default CtaBlue
