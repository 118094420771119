import React from 'react'

import './style/features.scss'

import Icon from '@eig-builder/core-ui/Icon'
import { Phonelink, Timer, ShoppingCart, ChatBubbleOutline, Language, Email, School, VerifiedUser, InsertChart, PermContactCalendar, Category } from '@material-ui/icons'

const Features = () => (<div className='row features'>
  <div className='col-md-4 col-sm-6'>
    <Phonelink className='icon' />
    <h3>Fully mobile responsive</h3>
    <p className='black-60'> Sites are fully optimized for both desktop and mobile screens.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <Timer className='icon' />
    <h3>Fast load time</h3>
    <p className='black-60'> Websites are created with page speed in mind for SEO optimization.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <ShoppingCart className='icon' />
    <h3>eCommerce</h3>
    <p className='black-60'> Add store functionality to start selling products.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <Email className='icon' />
    <h3>Email Marketing</h3>
    <p className='black-60'> Send beautiful emails to engage your audience or drive more sales.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <PermContactCalendar className='icon' />
    <h3>Contacts CRM</h3>
    <p className='black-60'> Store your business contacts, collect emails from your website or Ecommerce visitors.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <Category className='icon' />
    <h3>LogoBuilder</h3>
    <p className='black-60'> Create an amazing logo for your business or social branding.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <ChatBubbleOutline className='icon' />
    <h3>Beautiful blog</h3>
    <p className='black-60'> Share your ideas across the world by building a blog.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <Language className='icon' />
    <h3>Domain name</h3>
    <p className='black-60'> Make your website look professional with a custom domain.*</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <Email className='icon' />
    <h3>Professional email address</h3>
    <p className='black-60'> Get an email to match your website with our G Suite integration - <em>sold seperately</em>.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <School className='icon' />
    <h3>Intelligent builder</h3>
    <p className='black-60'> Automatically chooses the right layout, pulls in beautiful images, and even starts your content.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <VerifiedUser className='icon' />
    <h3>Website security</h3>
    <p className='black-60'> All of our websites come fully protected – <em>at no additional cost</em>.</p>
  </div>
  <div className='col-md-4 col-sm-6'>
    <InsertChart className='icon' />
    <h3>Website statistics</h3>
    <p className='black-60'> Access to all of the information you need around visitors to your site.</p>
  </div>
</div>
)
export default Features
