import React from 'react'
// import { Link } from 'gatsby'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import { getCurrencySymbol } from 'helpers/currency-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CtaBlue from '../components/cta-blue'
import Features from '../components/features'
import DiscountBanner from '../components/discount-banner'
import DiscountTerms from '../components/discount-terms'

class IndexPage extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title='Features'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'product_page'}
          flowType={'none'} />
        <DiscountBanner />
        <section className='bg-lightgray'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 page-heading'>
                <h2>Powerful features for your website</h2>
                <p className='body-large black-60'>We'll help you create a site that's (almost) completely built for you in seconds.
               You don't need to be a designer or have any technical knowledge to create the perfect website with us. Here are some of the features we provide.</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container'>
            <Features />
            <div className='row'>
              <div className='col-lg-10 body-large mx-auto'>
                <CtaBlue
                  eventName='start_onboarding_feature'
                  title={'Try it first.  No commitment necessary.'}
                  buttonTitle={'Try it for free'}
                  buttonLink={process.env.GATSBY_SIGNUP_SITE_URL}
                  currentPage={'features'}
                  dataElementLocation={DataElementLocations.BODY} />
              </div>
            </div>
            <div className='row footnote' style={{ marginBottom: 32 }}>
              <div className='col-md-8 mx-auto text-center'>*  Qualified 1 year or 2 year plans include a free domain registration for one year.
            If you wish to cancel, there is a non-refundable {getCurrencySymbol().domainPrice} domain fee.</div>
            </div>
          </div>
          <DiscountTerms />
        </section>
      </Layout>
    )
  }
}

export default IndexPage
